
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { State, Getter, Action, Mutation, namespace } from "vuex-class";

@Component({
  inheritAttrs: false
})
export default class InputPassword extends Vue {
  password: string = "";
  type: string = "password";
  switchType() {
    this.type = this.type === "password" ? "text" : "password";
  }
}
